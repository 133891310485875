const dayInMilliseconds = 1 * 24 * 60 * 60 * 1000;

const setCookie = (
  cookieName: string,
  cookieValue: string,
  cookieDuration: number = dayInMilliseconds
) => {
  const cookieExpiryDate = new Date(+new Date() + cookieDuration).toUTCString();
  const domain =
    window.location.host.indexOf("larchworkflow.com") > -1
      ? ".larchworkflow.com"
      : window.location.host.indexOf("mybrandstream") > -1
      ? ".mybrandstream.com"
      : "localhost";

  document.cookie = `${cookieName}=${cookieValue}; expires=${cookieExpiryDate}; path=/; domain=${domain}`;
};

const deleteCookie = (cookieName: string) => {
  setCookie(cookieName, "", -10000);
};

/**
 * Source: https://stackoverflow.com/a/25490531
 */

const readCookie = (cookieName: string) =>
  document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)")?.pop() ||
  "";

export { setCookie, readCookie, deleteCookie };
