import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';

import Injector from './core/utils/Injector';
import Routes from './Routes';

const basePath = Injector.config('basePath');

const Router = () => {
  useEffect(() => {
    // Force Redirection to an external domain name

    /**
     * Basically we use the server config like nginx to force redirection to a new domain name
     * In this case we can't do it yet by using AWS
     * The code below is a temporary solution to force the redirection until AWS allows us to do it
     */

    if (Injector.config('is_redirect_mhgfusion_to_mybrandstream')) {
      const currentUrlOrigin = window.location.origin;

      if (currentUrlOrigin.includes('mhgfusion')) {
        const {pathname, search} = window.location;
        const newUrl = currentUrlOrigin.replace('mhgfusion', 'old.larchworkflow') + pathname + search;

        window.location.replace(newUrl);
      }
    }
  }, []);


  return (
    <BrowserRouter basename={basePath}>
      <Routes />
    </BrowserRouter>
  );
};

export default Router;
